@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-background-color: #174a7c;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
}

.bg-maxx-blue {
  background-color: var(--header-background-color);
}

/* textarea::placeholder{
  transform: translateY(-0.5vh);
} */
.header {
  background-repeat: no-repeat;
  position: fixed;
  min-height: 10%;
  width: 100%;
  z-index: auto;
  background-color: var(--header-background-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3%;
  color: white;
  font-family: "Orbitron", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-shadow: 1px 1px 2px #10355a;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.maxxLogo {
  /*Create a dynamic width for image*/
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  opacity: 1;
}

h1 {
  color: #3274b6;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 42px;
  font-weight: normal;
  line-height: 48px;
  text-align: center;
  text-shadow: 1px 1px 2px #10355a;
}

/* video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/background-image.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  z-index: 999;
} */

.bg-img {
  /* background-image: url("../images/background-image.jpg"); */
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.chatBox1 {
  width: 36%;
  display: flex;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 0px;
  flex-direction: row;
  justify-content: center;
}


@media (min-width: 767px) and (max-width: 804px) {
  .chatBox1 {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .chatBox1 {
    width: 90%;
    height: auto;
  }
}

.formStyle {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.keyboard-open .formStyle {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .formStyle {
    margin-bottom: 0%;
  }
}

button {
  background-color: rgba(24, 37, 111, 0);
  border: none;
  font-size: 32px;
  cursor: pointer;
}

@media (max-width: 767px) {
  button {
    background-color: rgba(24, 37, 111, 0);
    border: none;
    font-size: 27px;
    cursor: pointer;
    margin-right: 10px;
  }
}

i {
  color: rgba(255, 255, 255, 0.689);
  font-size: 2.5rem;
}

#chat-icon:hover {
  opacity: 0.7;
}

.fa-circle-arrow-up {
  color: hsl(235.2deg 100% 80.39%);
}

textarea[type="text"] {
  border-radius: 2em;
  flex: 1;
  width: 80%;
  height: 63px;
  font-size: 1.2em;
  /* background-color: rgba(2, 83, 133, 0.466); */
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  /* border-radius: 5px; */
  border: 1px solid #ccc;
  resize: none;
  font-family: Arial, sans-serif;
  color: #000;
  padding-left: 2%;
  margin-left: 6%;
  padding-top: 2%;
  padding-bottom: 3%;
  padding-right: 2%;
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
textarea[type="text"]::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  textarea[type="text"] {
    width: 51%;
    height: 40px;
    margin: auto;
    padding: 4px 0 0 3%;
  }
}

/* textarea[type="placeholder"] {
  color: black;
} */

.messenger {
  height: 50px;
  display: flex;
}

.chatMenu {
  flex: 1;
}

@media (max-width: 666px) {
  .chatMenu {
    display: none;
  }

  .chatOnline {
    display: none;
  }
}

.chatBox {
  padding-bottom: 40px;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  height: calc(100vh - 80px);
}

@media (max-width: 767px) {
  .chatBox {
    height: calc(100vh - 50px);
  }
}

.chatOnline {
  flex: 1;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chatBoxWrapper::-webkit-scrollbar {
  display: none;
}

.chatBoxTop {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messages-container {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}

.message {
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
}

.messageTop {
  display: flex;
  max-width: 80%;
}


.messageText {
  font-family: Helvetica;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  background-color: #0f4b7d;
  color: #fff;
  max-width: 96%;
  width: fit-content;
  word-wrap: break-word;
  white-space: pre-line;
}

p {
  font-family: Helvetica;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  background-color: #132ddb;
  color: white;
  max-width: 240px;
  width: fit-content;
  word-wrap: break-word;
}

.messageBottom {
  font-size: 12px;
}

.message.own {
  align-items: flex-end;
}

.message.own .messageText {
  background-color: #f78f1e;
  color: rgb(0, 0, 0);
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 35px;
  margin-top: 34px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 0.2s infinite linear alternate;
  animation-delay: 0.5s;
}

@media (max-width: 767px) {
  .chatBox {
    margin-bottom: 40px;
    margin-left: 0;
    margin-top: 0;
  }
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }

  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding: 8px;
}

th {
  border: 1px solid black;
  padding: 8px;
}