body {
    background-color: #508bfc !important;
}

.error {
    color: red;
}

.forget {
    cursor: pointer;
}

.mb-minus-3 {
    margin-top: -40px;
}

.mx_center {
    display: flex;
    justify-content: center;
    align-items: center;
}